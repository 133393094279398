import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';

const PDFS = ({ data }) => {
  const StyledPDFS = styled.div`
    .top-bg {
      height: 30rem;
      position: relative;
      h1 {
        position: absolute;
        bottom: -5rem;
        left: 50%;
        margin-bottom: 0;
        transform: translate(-50%, -50%);
        color: #ffffff;
        background: ${props => props.theme.colors.org};
        padding: 1.5rem 2.5rem;
        font-size: 2.5rem;
      }
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 8rem;
      margin-bottom: 8rem;
      .pdf-block {
        background: #e8e8e8;
        margin: 0 1rem;
        padding: 1rem 2rem;
        margin-bottom: 2rem;
        border-radius: 1.5rem;
        &:hover {
          background: #555555;
          a {
            color: #ffffff;
          }
        }
        a {
          color: #666666;
          text-decoration: none;
        }
      }
    }
    @media (max-width: 768px) {
      .top-bg {
        height: 20vh;
      }
      .content {
        flex-wrap: wrap;
        .method {
          width: 43%;
        }
      }
    }
  `;
  return (
    <Layout>
      <SEO title="Our PDFs" />
      <StyledPDFS>
        <div className="top-bg">
          <Img fluid={data.file.childImageSharp.fluid} />
          <h1>Our PDFs</h1>
        </div>
        <div className="content">
          {data.allWordpressWpPdfs.nodes.map(pdf => (
            <div className="pdf-block">
              <h1>
                <a
                  href={pdf.acf.pdf_file.url.source_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Our {pdf.acf.pdf_name}
                </a>
              </h1>
            </div>
          ))}
        </div>
      </StyledPDFS>
    </Layout>
  );
};

export const data = graphql`
  {
    file(relativePath: { eq: "pdfs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allWordpressWpPdfs {
      nodes {
        acf {
          pdf_name
          pdf_file {
            url {
              source_url
            }
          }
        }
      }
    }
  }
`;

export default PDFS;
